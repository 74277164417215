<template>
    <vue-html2pdf
        :show-layout="true"
        :float-layout="false"
        :enable-download="false"
        :preview-modal="true"
        margin="[10,10,10,10]"
        filename="Collection Report"
        :paginate-elements-by-height="1400"
        :pdf-quality="2"
        pdf-format="A4"
        pdf-orientation="portrait"
        pdf-content-width="auto"
        :manual-pagination="true"
        @progress="onProgress($event)"
        @beforeDownload="beforeDownload($event)"
        @hasDownloaded="hasDownloaded($event)"
        ref="html2Pdf"
    >
    <section slot="pdf-content">
        <section class="pdf-item">
            <div style="background-color:white;">
                <div class="col-md-12">
                    <div class="row" style="margin-top:20px">
                        <div class="col-md-6" >
                            <h3 class="page-header">
                            <i class="fa fa fa-calculator animated bounceInDown show-info"></i>
                                {{ setheadding() }}
                            </h3>
                        </div>
                        <!-- <div class="col-md-12 text-center" v-if="isprint==1">
                            <h3 class="page-header">
                               Collection Report
                            </h3>
                        </div> -->
                        <div class="col-md-6 text-right" v-if="viewno==1 && isprint==0">
                            <button @click="refresh()" class="btn btn-refresh" ><i class="fa fa-refresh"></i></button>
                        </div>
                        <div class="col-md-6 text-right" v-if="viewno==2 && isprint==0">
                            <button @click="back()" class="btn btn-primary" ><i class="fa fa-backward"></i>  Back</button>
                        </div>
                    </div>
                </div>
                
                <div v-if="viewno==1">
                    <div class="col-md-12">
                        <div class="col-md-12 info-box bg-warning text-white">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="col-md-12 bg-primary-dark ">
                                        <h4>Total Collection Amount <br> <i class="fa fa-inr"></i> {{totalrecieveamt}} </h4>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="col-md-12 bg-primary-dark ">
                                        <h4>Total Booking Amount <br> <i class="fa fa-inr"></i> .00</h4>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="col-md-12 bg-primary-dark ">
                                        <h4>Total Amount <br> <i class="fa fa-inr"></i> {{this.allpaymentsum}} </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <payment-search :currentpage="currentpage" :noofrec="noofrec"></payment-search> -->
                    <div class="col-md-12" v-if="isprint==0">
                        <div class="panel panel-cascade">
                            <div class="panel-body ">                   
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-horizontal cascde-forms">
                                            <div class="form-group CHK">
                                                <label class="col-lg-4 col-md-3 control-label">Select Type</label>
                                                <div class="col-lg-8 col-md-9">
                                                    <select class="form-control form-cascade-control input-small" v-model="paymenttype">
                                                        <option selected :value="0">SELECT</option>
                                                        <option :value="1" class="bg-sheme">EMI</option>
                                                        <option :value="2" class="bg-sheme">Cash</option>
                                                        <option :value="4" class="bg-sheme">SCHEMES</option>
                                                        <option :value="5" class="bg-sheme">DOWN PAYMENT</option>
                                                        
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="form-group CHK">
                                                <label class="col-lg-4 col-md-3 control-label">Customer Mobile</label>
                                                <div class="col-lg-8 col-md-9">
                                                    <input class="form-control form-cascade-control input-small" v-model="mobile" type="number"  />
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-lg-4 col-md-3 control-label">Paydate FROM</label>
                                                <div class="col-lg-8 col-md-9">
                                                    <vc-date-picker class="inline-block h-full" :model-config="modelConfig" v-model="transactiondatefrom">
                                                        <template v-slot="{ inputValue, togglePopover }">
                                                            <div class="flex items-center">
                                                                <button type="button"
                                                                    class="date-picker"
                                                                    @click="togglePopover()">
                                                                    <i class='bx bx-calendar'></i>
                                                                </button>
                                                                <input :value="inputValue" @click="togglePopover()"
                                                                    class="form-control form-cascade-control input-small"
                                                                    placeholder="dd/mm/yyyy" readonly />
                                                            </div>
                                                        </template>
                                                    </vc-date-picker>
                                                    <!-- <input class="form-control form-cascade-control input-small" v-model="transactiondatefrom" type="date" /> -->
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-lg-4 col-md-3 control-label">Entry Date FROM</label>
                                                <div class="col-lg-8 col-md-9">
                                                    <input class="form-control form-cascade-control input-small" v-model="entrydatefrom" type="date"/>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-lg-4 col-md-3 control-label">Zone<span style="color:rgba(255, 0, 0, 0.67)">*</span></label>
                                                <div class="col-lg-8 col-md-9">
                                                    <select class="form-control form-cascade-control input-small" v-model="zoneid">
                                                        <option selected="selected" value="">SELECT</option>
                                                        <option v-for="item in getmastertype(MASTER_ZONE_TYPES)" :key="item.labelid" :value="item.labelid">{{item.label}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 CHK">
                                        <div class="form-horizontal cascde-forms">
                                            <div class="form-group CHK">
                                                <label class="col-lg-4 col-md-3 control-label">Customer Name</label>
                                                <div class="col-lg-8 col-md-9">
                                                    <input class="form-control form-cascade-control input-small" v-model="customername" type="text"  />
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-lg-4 col-md-3 control-label">Customer LAN</label>
                                                <div class="col-lg-8 col-md-9">
                                                    <input class="form-control form-cascade-control input-small" v-model="manualid" type="text" />
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-lg-4 col-md-3 control-label">Paydate : TO</label>
                                                <div class="col-lg-8 col-md-9">
                                                    <input class="form-control form-cascade-control input-small" v-model="transactiondateto" type="date"  />
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-lg-4 col-md-3 control-label">Entry Date : TO</label>
                                                <div class="col-lg-8 col-md-9">
                                                    <input class="form-control form-cascade-control input-small" v-model="entrydateto" type="date" />
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <label class="col-lg-4 col-md-3 control-label">Recpt No</label>
                                                <div class="col-lg-8 col-md-9">
                                                    <input class="form-control form-cascade-control input-small" v-model="receiptno"  type="number" />
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="col-lg-12 col-md-9 flex-around-row">
                                                    <button class="btn btn-primary" @click="search()">Search</button>
                                                    <button class="btn btn-primary" @click="clear()">Clear</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="info-box bg-warning text-white">
                                <div class="info-icon bg-primary-dark" style="padding-bottom:5px;"> 
                                    <h4>
                                        <i class="fa fa-check-square-o"></i>
                                        Select REC BY
                                    </h4>
                                </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-3" v-for="(item) in employees" v-bind:key="item.id">
                                            <!-- <usercheck :empid="item.id"></usercheck>  {{item.name}} -->
                                            <input type="checkbox" 
                                            :value="item.id"     
                                            id="item.id" 
                                            v-model="checkedCategories" 
                                            @change="check($event)">{{item.name}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 pt-20" v-if="isprint==0">
                        <div class="row">
                            <div class="col-md-4">
                                <!-- <downloadexcel
                                    :data="this.allexcelpayments"
                                    :before-generate="startDownload"
                                    :before-finish="finishDownload"
                                    type="csv"
                                    name="filename.xls">
                                    <strong> GET EXCEL:</strong> <img src="../../assets/excel.png" width="30" height="30" style="cursor: pointer;">
                                </downloadexcel> -->
                            </div>
                            <div class="col-md-4">
                                <span class="control-label">Total Amount:</span> <span class="badge bg-seagreen"><i class="fa fa-inr"></i> {{this.allpaymentsum}}</span>
                            </div>
                            <div class="col-md-4" style="text-align:right;" v-if="isprint==0">
                                <input v-if="!clickpdf" type="button" id="btnprint" class="btn bg-success btn-xs text-white" @click="generateReport()" value="Go to Print" >
                                <span v-if="clickpdf" class="spinner" @dblclick="clickpdf=false"></span>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-md-12">
                        <div class="col-md-12 mt-20" v-if="isprint==0">
                            <div class="row">
                                <div class="col-md-2">
                                    <strong>Show Rec.</strong>
                                </div>
                                <div class="col-md-2">
                                    <select class="form-control col-mb-1" v-model="noofrec" id="rec" @change="refresh()">
                                        <option value=10>10</option>
                                        <option value=25>25</option>
                                        <option value=50>50</option>
                                        <option value=100>100</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="panel">
                            <div class="panel-body">
                                <table class="table users-table table-condensed table-hover table-sm table-bordered">
                                    <thead class="table-font">
                                        <tr>
                                            <th>#</th>
                                            <th>LAN No.</th>
                                            <th>Customer Name</th>
                                            <th v-if="isprint==0">Zone</th>
                                            <th>Contact</th>
                                            <th>Address</th>
                                            <th>Rcpt No.</th>
                                            <!-- <th>Amount</th> -->
                                            <th>Payment Date</th>
                                            <th>Receive Payment</th>
                                            <th>Received By</th>
                                            <th>Entry By</th>
                                            <th>Type</th>
                                        </tr>
                                    </thead>
                                    <tbody class="table-font" v-for="(item,index) in allpayments" v-bind:key="item.id">
                                        <tr>
                                            <td>{{index+1}}</td>
                                            <td >
                                                <div v-if="item.reftransactiontype==3 || item.reftransactiontype==2">
                                                    <a href="#" v-if="item.loanemi!=null && item.loanemi.loanaccount!=null && item.paymenttype==1" @click="loandetails(item.loanemi.loanaccount.id)">{{item.loanemi.loanaccount.manualid}}</a>
                                                    <a href="#" v-if="item.saletransaction!=null && item.saletransaction.loandetails!=null && item.paymenttype==5" @click="loandetails(item.saletransaction.loandetails.id)">{{item.saletransaction.loandetails.manualid}}</a>
                                                </div>
                                                <div v-else-if="item.paymenttype==4 || item.reftransactiontype==4">    
                                                    <a href="#" v-if="item.ongoingschemeemi!=null">{{item.ongoingschemeemi.ongoingscheme.generatedid}}</a>
                                                </div>
                                            </td>
                                            <td>{{item.customer==null?'':item.customer.name}}</td>
                                            <td v-if="isprint==0">{{item.customer==null?'':getMasterLabelByType(item.customer.zoneid,MASTER_ZONE_TYPES)}}</td>
                                            <td><display-mobiles v-if="item.customer!=null" :mobiles="item.customer.mobiles"></display-mobiles></td>
                                            <td><display-first-address v-if="item.customer!=null" :addresses="item.customer.addressess"></display-first-address></td>
                                            <td>{{item.receiptno}}</td>
                                            <!-- <td><span v-if="item.loanemi!=null && item.saletransaction==null"><i class='bx bx-rupee'></i> {{item.loanemi.tobepaid}}</span></td> -->
                                            <td><i class='bx bxs-calendar'></i> {{moment(item.transactiondate).format("DD-MM-YYYY")}}</td>
                                            <td>
                                                <span><i class='bx bx-rupee'></i> {{item.amount}}</span><br>
                                                <span class="badge btn-view" style="margin-top: 16px;" v-if="item.paymentregister!=null">{{item.paymentregister.name}}</span>
                                            </td>
                                            <td><div v-if="item.collectedby!=null">{{item.collectedby.name}}</div></td>
                                            
                                            <td>
                                                <span v-if="item.enteredby!=null">{{item.enteredby.name}}</span><br>
                                                {{moment(item.created_at).format('DD-MM-YYYY')}}<br>
                                                {{moment(item.created_at).format('hh:mm:s A')}}
                                                
                                            </td>
                                            <td>
                                                <span v-if="item.paymenttype==null"></span>
                                                <span v-else-if="item.paymenttype==1">EMI</span>
                                                <span v-else-if="item.paymenttype==2">Cash</span>
                                                <span v-else-if="item.paymenttype==3">Advance</span>
                                                <span v-else-if="item.paymenttype==4">Scheme</span>
                                                <span v-else-if="item.paymenttype==5">D.P.</span>
                                            </td>
                                        </tr>
                                        <div v-if="page1(index)" class="html2pdf__page-break" style="margin-bottom:20px;"></div>
                                    </tbody>
                                </table>
                                
                            </div>
                        </div>
                        <div class="col-md-12" style="margin-top: 20px" v-if="isprint==0">
                            <nav aria-label="Page navigation example" style="text-align: right !important">   
                                <paginate
                                    style="margin: 0px !important; float: right"
                                    :page-count="noofpages"
                                    :click-handler="clickCallback"
                                    :prev-text="'&laquo;'"
                                    :next-text="'&raquo;'"
                                    :container-class="'pagination'">
                                </paginate>
                            </nav>
                        </div>
                    </div>
                </div>
                <sales-details v-if="viewno==2"></sales-details>
            </div>
        </section>
    </section>
</vue-html2pdf>
</template>
<script>
import { mapGetters } from 'vuex'
import VueHtml2pdf from 'vue-html2pdf'
// import downloadexcel from "vue-json-excel";
import Paginate from "vuejs-paginate";
// import PaymentSearch from './PaymentSearch.vue'
import Constants from '../../components/utilities/Constants.vue'
import DisplayFirstAddress from '../../components/address/DisplayFirstAddress.vue'
import DisplayMobiles from '../../components/mobile/DisplayMobiles.vue'
import moment from 'moment'
import SalesDetails from '../salesmanager/SalesDetails.vue'
export default {
    mixins:[Constants],
     components: { DisplayMobiles, DisplayFirstAddress,SalesDetails,Paginate,VueHtml2pdf},
     data() {
        return {
            checkedCategories:[],
            moment:moment,
            currentpage:1,
            noofrec:10,
            searchrec:[],
            paymenttype:'',
            receiptno:'',
            mobile:'',
            customername:'',
            manualid:'',
            entrydatefrom:null,
            entrydateto:null,
            zoneid:'',
            transactiondatefrom:null,
            transactiondateto:null,
            employeeids:'',
            clickpdf:false,
            modelConfig: {
                type: 'string',
                mask: 'YYYY-MM-DD', // Uses 'iso' if missing
            },
            isprint:0,
        }
     },
     
    computed:{
        
        ...mapGetters([
		      'edititem','allpayments','viewno','allpaymentscount','allpaymentsum','employees'
	    ]),
        noofpages(){
            return Math.ceil(parseInt(this.allpaymentscount) / parseInt(this.noofrec))
        },
        calculatesum(){
            let total = 0;
            this.allpayments.forEach(function(item){
                total = total + parseInt(item.amount)
            })
            return total;
        },
        totalrecieveamt(){
            let total = 0;
            this.allpayments.forEach(function(item){
                if(item.schemeemipayment!=null){
                    total = total + parseInt(item.schemeemipayment.receviedamount)
                }
            })
            return total;
        }
    },
    watch: {
        '$route.params.isactive'() {
            this.refresh()
        },
        
    },
    mounted() {
        this.refresh();
        this.scroll();
        this.initialize();
        // this.getexcel();
    },
    methods: {
        page1(index){
            if(index==5){
                return true
            }
            if((index-5)%9==0){
                return true
            }
            return false
        },
        check (e) {
            this.$nextTick(() => {
                console.log(this.checkedCategories, e)
                this.search();
            })
        },
        clickCallback (currentpage) {
            this.currentpage=currentpage
            this.$store.commit("assigncurrentpage",currentpage);
            this.refresh();
        },
        refresh(){
            this.$store.dispatch('fetchemployees')
            this.checkall=false
            this.search();
            // this.$store.dispatch('fetchallpaymentsum')
        },
        initialize(){
            this.currentpage=1,
            this.noofrec=10,
            this.searchrec=[],
            this.paymenttype='',
            this.receiptno='',
            this.mobile='',
            this.customername='',
            this.manualid='',
            this.entrydatefrom=null,
            this.entrydateto=null,
            this.zoneid='',
            this.transactiondatefrom=null,
            this.transactiondateto=null,
            this.employeeids=''
        },
        search(){
            this.$store.commit("assignloadingstatus",1)
            let param = {paymenttype:this.paymenttype,receiptno:this.receiptno,mobile:this.mobile,currentpage:this.currentpage,
                    customername:this.customername,manualid:this.manualid,transactiondatefrom:this.transactiondatefrom,
                    transactiondateto:this.transactiondateto,zoneid:this.zoneid,noofrec:this.noofrec,
                    entrydatefrom:this.entrydatefrom,entrydateto:this.entrydateto,empid:this.checkedCategories}
            if(this.$route.params.isactive=='emicollectionreport'){
                window.axios.post('api/allpayments/fetch',param)
                    .then((response) => this.processsearchResponse(response.data))
                    .catch((err) => {
                    console.log(err)
                });
            }else{
                window.axios.post('api/allpayments/fetchschemepayment',param)
                    .then((response) => this.processsearchResponse(response.data))
                    .catch((err) => {
                    console.log(err)
                });
            }
            window.axios.post('api/allpayments/fetch/count',param)
    			.then(response=>this.processCountResponse(response.data))
    			.catch(error=>console.log(error));

            window.axios.post('api/allpayments/allpaymentsum',param)
    			.then(response=>this.processSumResponse(response.data))
    			.catch(error=>console.log(error));
        },
        processSumResponse(data){
            this.$store.commit("assignallpaymentsum",data)
        },
        clear(){
            this.initialize();
            this.search();
        },
        loandetails(id){
           let param = {id:id}
            this.$http.post('api/loan/details',param)
            .then((response) => this.processloanResponse(response.data))
            .catch((err) => {
                console.log(err)
            });
        },
        processCountResponse(data){
            this.$store.commit('assignallpaymentscount',data)
        },
        processsearchResponse(data){
            this.$store.commit("assignloadingstatus",0)
            this.$store.commit("assignallpayments",data.payments)
        },
        processloanResponse(data){
            this.$store.commit('assignedititem',data)
            this.$store.commit('assignviewno',2)
            this.$children.localviewno=1
        },
        back(){
            this.$store.commit('assignviewno',1)
        },
        scroll () {
            window.onscroll = () => {
                let bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight

                if (bottomOfWindow) {
                    console.log("scroll down") // replace it with your code
                }
            }
        },
        // async getexcel(){
        //   await this.$http.post('api/allpayments/exportCsv')
    	// 		.then((response)=>{
        //           this.searchrec= response.data
        //         })
    	// 		.catch(error=>console.log(error));
        // },
        startDownload(){
            this.$store.commit("assignloadingstatus",1)
        },
        finishDownload(){
            this.$store.commit("assignloadingstatus",0)
        },
        async beforeDownload ({ html2pdf, options, pdfContent }) {
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages()
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i)
                    pdf.setFontSize(11)
                    pdf.setTextColor(150)
                    pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
                } 
            }).save()
        },
        generateReport () {
            this.isprint=1
            this.$refs.html2Pdf.generatePdf()
        },
        onProgress(progress) {
            this.progress = progress;
            console.log(`PDF generation progress: ${progress}%`)
        },
        hasDownloaded(){
            this.$parent.generate=false
            this.isprint=0
        },
        setheadding() {
            if (this.$route.params.isactive == 'emicollectionreport') {
                return "EMI Collection Payment"
            } else if (this.$route.params.isactive == 'schemecollectionreport') {
                return "Scheme Collection Payment"
            }
        }
        
    },
}
</script>
<style scoped>
.col-lg-8{
    float: right;
}
.panel-body{
    font-size: 14px;
}
.input-small{
    height: 32px;
}
</style>